

























import { projectModule, userModule } from '@/store'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { canPerformAction } from '@/helpers/canPerformAction'
import { MultipleDocumentsService } from '@/services/multipleDocuments'

import NoItems from '@/components/partials/NoItems.vue'
import AddComment from '@/components/comments/AddComment.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { CommentResource } from '@/models/comments/CommentResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'

// component
@Component({
  components: {
    NoItems, AddComment, SmallLoader
  }
})
export default class FlexibleDocumentComments extends Vue {

  @Prop() private readonly commentFilter!: string | null

  @Prop() private project!: ProjectResource

  @Prop() private document!: FlexibleDocumentResource

  private resetMessage: boolean = false

  private get canComment(): boolean {
    return this.document.canPerformAction('can_comment')
  }

  private multipleDocumentsService: MultipleDocumentsService = new MultipleDocumentsService({ project_id: this.project.id })

  @Watch('document.id')
  private onDocumentIdChange(id: number, oldId: number) {
    if (id === oldId) return
    this.resetMessage = true
    this.$nextTick(() => {
      this.resetMessage = false
    })
  }

  @Watch('document.comments.data.length')
  private onProposalChange(): void {
    this.$nextTick(() => {
      this.scrollBottom()
    })
  }

  private get filteredComments(): CommentResource[] {
    if (this.commentFilter) {
      return this.document.comments.items.filter((c) => {
        if (this.commentFilter === 'resolved') {
          return c.status === 'resolved' || c.status === 'declined'
        }
        return c.status === this.commentFilter
      })
    }

    return this.document.comments.items
  }

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private get users(): ProjectUserResource[] {
    return projectModule.users
  }

  private created(): void {
    this.scrollBottom()
  }

  private deleteComment(id: number): void {
    this.document.comments.deleteComment(id)
  }

  private async getComments(): Promise<void> {
    try {
      await this.multipleDocumentsService.getComments()
      this.scrollBottom()
    } catch (e) {
      console.error(e)
    }
  }

  private async addComment(message: string): Promise<void> {
    this.document.comments.loading = true
    try {
      await this.document.comments.post(message)
      this.$emit('refresh')
      this.scrollBottom()
    } finally {
      this.document.comments.loading = false
    }
  }

  private scrollBottom(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    wrapper.scrollTop = wrapper.scrollHeight
  }
}
